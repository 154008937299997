









































































































































































































import { Component, Vue } from "vue-property-decorator";
import { Region } from "@/models/Region";
import {
  REGION_REQUEST,
  REGION_UPDATED,
  REGION_LOADED,
} from "@/store/actions/main";
import {
  STATE_PROGRAM_REQUEST,
	SLICE_UPDATE,
  SLICE_DELETE,
  SLICE_CREATE,
} from "@/store/actions/stateProgram";

@Component({})
export default class SlicesTable extends Vue {
  private slices: any = [];
	private regions: any = [];
  private form: any = {};
  private slicesHeaders = [
    {
      text: "Регион",
      value: "region_id",
      align: "start",
      width: "40%",
    },
    {
      text: "Область",
      value: "district_id",
      align: "start",
      sortable: false,
      width: "40%",
    },
    // {
    //   text: "По годам",
    //   value: "year",
    //   align: "center",
    //   sortable: false,
    //   width: "10%",
    // },
		{
      text: "Действия",
      value: "actions",
      align: "center",
      sortable: false,
      width: "10%",
    },
  ];
  private loading: boolean = false;
  private itemId: number = 0;
  private currentItem: any = {} as any;
  //Snackbar
  private messageText = "Не удалось выполнить";
  private messageColor = "red";
  private message = false;
  private search = "";
  private itemToDelete: any = {};
  private deleteDialog: boolean = false;
  private deleteSnackbar: boolean = false;
  private deleteDisabled: boolean = false;
  //options
  private tablePage = 1;
  private tablePageCount = 0;
  private itemsPerPage = 15;
  //Initialise methods
  private initIfReady() {
    //return true if started to init self.
    let needReload = [] as boolean[];
		needReload.push(
      this.needReload(
        this.$store.getters.REGION_TABLE,
        this.$store.getters.REGION_LOADED
      )
    );
    for (let i = 0; i < needReload.length; i++) {
      if (needReload[i] == true) {
        return false;
      }
    }
    console.log("TRUE INIT SELF");
    this.initSelf();
    return true;
  }
  private needReload(table, updated) {
    /*
		data is a table loading from request.
		updated is flag that is false when table has old data and need to be reloading.
		return true if need reload.
		*/
    if (table == null || !updated) return true;
    return false;
  }
  private tryToInitSelf() {
    this.loading = true;
    //const data = this.getParams();
    if (this.initIfReady()) return;
		if (
      this.needReload(
        this.$store.getters.REGION_TABLE,
        this.$store.getters.REGION_LOADED
      )
    ) {
      this.$store.dispatch(REGION_REQUEST).then(() => {
        this.initIfReady();
      });
    }
  }
	private initSelf() {
    console.log("INIT SELF");
		this.regions = this.$store.getters.REGION_TABLE.data;
    this.loading = false;
    console.log("loading");
  }
  //Methods
	private toString(str) {
		return str as string;
	}
  private isDeleting(id) {
    if (this.itemToDelete.id == id) return true;
    return false;
  }
  private cleanDeleting() {
    this.itemToDelete = {} as any;
  }
  private toCreate() {
		this.$router.push({ name: "SlicesCreate", params: { id: this.currentItem.id } });
  }
  private toUpdate(item) {
    console.log("item: ", item);
    const parsed = JSON.stringify(item);
    localStorage.setItem("slice", parsed);
    this.$router.push({ name: "SlicesUpdate", params: { id: this.currentItem.id } });
  }
  
  //Methods
  // private deleteItem(id) {
  //   this.$store.dispatch(INDICATOR_DELETE, id).then(() => {
  //     this.tryToInitSelf();
  //   });
  // }

  private deleteItem(item: any, confirmation: boolean) {
    this.itemToDelete = item;
    if (confirmation) {
      this.deleteDialog = true;
    } else {
      console.log(item);
      console.log("Delete!");
      this.$store.dispatch(SLICE_DELETE, item.id).then(() => {
        this.preInitSelf();
        this.deleteSnackbar = true;
        this.itemToDelete = {};
        this.deleteDisabled = false;
      });
      this.deleteDialog = false;
    }
  }
	private getRegion(id){
    for(let i = 0; i < this.regions.length; i++){
      if(id == this.regions[i].id)
        return this.regions[i].title;
    }
  }
	private getDistrict(region_id, id){
		let region = {} as any;
		reg_search: for(let i = 0; i < this.regions.length; i++){
      if(region_id == this.regions[i].id){
				region = this.regions[i];
				break reg_search;
			}
    }
		for(let i = 0; i < region.districts.length; i++){
			if(id == region.districts[i].id)
        return region.districts[i].title;
		}
  }
	private preInitSelf(){
		this.$store.dispatch(STATE_PROGRAM_REQUEST).then(() => {
      let statePrograms = this.$store.getters.STATE_PROGRAM_TABLE.data;
      let slices = [] as any;
      let data = this.toString(localStorage.getItem("currentStateProgram"));
      let currentStateProgram = JSON.parse(data);
      this.currentItem = currentStateProgram;
      for(let i = 0; i < statePrograms.length; i++){
        if(statePrograms[i].id == currentStateProgram.id){
          this.$router.currentRoute.meta.breadcrumb[2].text = statePrograms[i].name;
          this.$router.replace({name: "Slices", params: {id: currentStateProgram.id}})
          this.currentItem = statePrograms[i];
					slices = statePrograms[i].slices;
          break;
        }
      }
      let result = [] as any;
      let exist = false;
      outer: for(let i = 0; i < slices.length; i++){
        exist = false;
        inner: for(let j = 0; j < result.length; j++){
          if(slices[i].region_id == result[j].region_id
          && slices[i].district_id == result[j].district_id){
            exist = true;
            console.log("found already existing slice region, district", slices[i].region_id, slices[i].district_id);
            break inner;
          }
        }
        if(!exist){
          result.push(slices[i]);
        }
      }
      this.slices = result;
      this.tryToInitSelf();
    });
	}
  //Hooks
	private created(){
		this.preInitSelf();
	}
  private mounted() {
    
  }
}
